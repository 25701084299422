import React, { useEffect } from "react";
const Terms = () => {
  return (
    <>
      <div className="sub-page-title">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
              <div className="section-heading-14 text-center">
                <h1>NanoThings</h1>
                <h2
                  style={{
                    fontSize: "40px",
                    fontWeight: 600,
                    fontFamily: "Open Sans",
                  }}
                >
                  Terms and Conditions
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container pt-lg-5">
        <div className="col-xl-12 col-lg-12 col-md-12">
          <div className="content section__heading">
            <p
              style={{ color: "#000", fontWeight: 500 }}
              className="terms-text"
            >
              By accessing or using the Software and Services owned or operated
              by NanoThings, Inc., whether through our software or website (our
              "Services"), you are accepting and agreeing to be bound by the
              terms and conditions set forth below (these "Terms"). We may
              revise these Terms from time to time, and the most current version
              will always be posted on our website. If a revision, in our sole
              discretion, is material, we will notify you. By continuing to
              access or use the Product after revisions become effective, you
              agree to be bound by the revised Terms. Because our Services
              continue to evolve, we may change or discontinue all or any part
              of the Services, including any features offered under our
              subscription services at any time and without notice, at our sole
              discretion.
              <b>
                {" "}
                For residents of certain countries, you agree to the arbitration
                agreement and class action waiver described in Section 26 to
                resolve disputes with NanoThings.
              </b>
            </p>
            <br/> <h4 className="terms-title">1- License</h4>
            <p
              style={{ color: "#000", fontWeight: 500 }}
              className="terms-text"
            >
              Subject to your compliance with these Terms, NanoThings grants you
              a limited non-exclusive, non-transferable license to download and
              install a copy of our firmware and firmware documentation (the
              "Software") on devices that you own or control and to run such
              copies of the Software and use our Services solely for your own
              purposes. We reserve all rights in and to the Software and
              Services not expressly granted to you under these Terms. You may
              not: (i) copy, modify or create derivative works based on the
              Software or Services; (ii) distribute, transfer, sublicense, lend
              or rent the Software to any third party; (iii) reverse engineer,
              decompile or disassemble the Software.
            </p>

            <br/> <h4 className="terms-title">2- Creating an Account</h4>
            <p
              style={{ color: "#000", fontWeight: 500 }}
              className="terms-text"
            >
              In order to use certain features of our Services, you may need to
              have an account with us. By creating an account, you represent
              that (a) all required information you provide is truthful and
              accurate; (b) you are of legal age to agree to these Terms; and
              (c) your use of our Services does not violate any applicable law
              or regulation or these Terms. You are responsible for maintaining
              the confidentiality of your password and for any and all use of
              your account. You should notify us immediately if you suspect any
              unauthorized use of your account or access to your password. You
              may not (a) use the account or username of any other user; (b)
              allow someone else to use your account or username; or (c) sell,
              lend, transfer, or otherwise share your account, temporarily or
              permanently, with someone else. If you violate these Terms, we
              reserve the right to issue you a warning, suspend or even
              terminate your account (along with your ability to access and use
              the Services).
            </p>

            <br/> <h4 className="terms-title">3- Subscription</h4>
            <p
              style={{ color: "#000", fontWeight: 500 }}
              className="terms-text"
            >
              You are required to obtain a Subscription to gain access to the
              Services. You will be charged a subscription fee, plus any
              applicable taxes and other charges (a “Subscription Fee”), at the
              beginning of your Subscription, and at the start of each billing
              period thereafter, at the then-current Subscription Fee. You may
              have the option to pay your Subscription Fee on a monthly basis (a
              “Monthly Subscription”), or on an annual basis (an “Annual
              Subscription”). If you have a Monthly Subscription, we (or our
              third-party payment processor) will automatically charge you each
              month on the anniversary of the commencement of your Monthly
              Subscription, using the Payment Information you have provided
              until you cancel your Monthly Subscription. If you have an Annual
              Subscription, we (or our third-party payment processor) will
              automatically charge you annually on the anniversary of the
              commencement of your Annual Subscription, using the Payment
              Information you have provided until you cancel your Annual
              Subscription. If you have an Annual Subscription, we will send you
              a reminder at least thirty (30) days prior to each renewal with
              the then-current Subscription Fee. We reserve the right to change
              our Subscription Fees for Monthly and Annual Subscriptions. We
              will provide you advance notice of any increase in the
              Subscription Fees applicable to your Subscription. <br />
              You may change your Monthly Subscription to an Annual Subscription
              within your account, or by contacting us via{" "}
              <span>
                <a
                  style={{ color: "#2aace3", cursor: "pointer" }}
                  href="https://nanothingsinc.com/support"
                >
                  www.nanothingsinc.com/support
                </a>
              </span>{" "}
              , if you signed up on nanothingsinc.com. If you change or upgrade
              your subscription, your billing date may also change. If you
              change from a Monthly Subscription to an Annual Subscription, on
              your next billing date, you will be charged the then-current
              Subscription Fee for an Annual Membership, and your Annual
              Subscription will renew on an annual basis. If the option to
              change from an Annual Subscription to a Monthly Subscription is
              available, and you change to a Monthly Subscription, at the next
              renewal date for your Annual Subscription, you will be charged the
              then-current Subscription Fee for a Monthly Membership, and your
              Monthly Subscription will renew on a Monthly basis. If you upgrade
              your subscription from a NanoThings Pro Subscription or Pro Plus
              Subscription, you will be refunded a pro rata amount for remaining
              time (if any) on your existing NanoThings Subscription and then be
              charged for the full annual Subscription. Your new Subscription
              will then automatically renew one year after the date of your
              upgrade.
            </p>

            <br/> <h4 className="terms-title">4- Cancellation of a Subscription</h4>
            <p
              style={{ color: "#000", fontWeight: 500 }}
              className="terms-text"
            >
              YOUR PURCHASE OF A SUBSCRIPTION IS FINAL AND YOU WILL NOT BE ABLE
              TO CANCEL THE PURCHASE AND/OR RECEIVE A REFUND AT ANY TIME. But if
              something unexpected happens in the course of completing the
              Transaction for your purchase of a Subscription, we reserve the
              right to cancel your Subscription for any reason; if we cancel
              your Subscription we’ll refund any payment you have already
              remitted to us for such Subscription. Without limiting the
              foregoing, you may cancel your Subscription at any time, but
              please note that such cancellation will be effective at the end of
              the then-current Monthly or Annual Subscription period. If you
              signed up on nanothingsinc.com, you may cancel your Subscription
              by logging into your account at{" "}
              <span>
                <a
                  style={{ color: "#2aace3", cursor: "pointer" }}
                  href="https://nanothingsinc.com/myaccount"
                >
                  www.nanothingsinc.com/myaccount
                </a>
              </span>{" "}
              and selecting “Cancel Subscription.” YOU WILL NOT RECEIVE A REFUND
              OF ANY PORTION OF THE SUBSCRIPTION FEE PAID FOR THE THEN CURRENT
              SUBSCRIPTION PERIOD AT THE TIME OF CANCELLATION. You will be
              responsible for all Subscription Fees (plus any applicable taxes
              and other charges) incurred for the then-current subscription
              period. If you cancel, your Subscription will continue until the
              end of your then-current subscription period and will then
              terminate without further charges.
            </p>

            <br/> <h4 className="terms-title">5- Privacy And Consent To Use Data</h4>
            <p
              style={{ color: "#000", fontWeight: 500 }}
              className="terms-text"
            >
              Please refer to our Privacy Policy{" "}
              <span>
                <a
                  style={{ color: "#2aace3", cursor: "pointer" }}
                  href="https://nanothingsinc.com/privacy"
                >
                  (www.nanothingsinc.com/privacy)
                </a>
              </span>
              for information on how we collect, use and disclose information
              from our users.
              <br /> You agree that NanoThings may collect and use technical
              data and related information—including but not limited to
              technical information about your device, system and
              Softwarelication software—that is gathered periodically to
              facilitate the provision of software updates, product support, and
              other services to you (if any) related to the NanoThings products
              and Services. NanoThings may use this information to improve its
              products or to provide services or technologies to you.
              <br /> You acknowledge that NanoThings is not agreeing to maintain
              or store all technical data or information relating to your use of
              the Services and there are situations where such data or
              information may be lost or otherwise unrecoverable.
            </p>

            <br/> <h4 className="terms-title">6- General Prohibitions</h4>
            <p
              style={{ color: "#000", fontWeight: 500 }}
              className="terms-text"
            >
              You agree not to do any of the following:
            </p>
            <p
              style={{ color: "#000", fontWeight: 500 }}
              className="terms-text"
            >
              6.1- Use the Services for the benefit of any third party or in any
              manner not permitted by these Terms;
            </p>
            <p
              style={{ color: "#000", fontWeight: 500 }}
              className="terms-text"
            >
              6.2- Use the Services to track the location of, or collect any
              personally identifiable information from, any other person without
              their express permission;
            </p>
            <p
              style={{ color: "#000", fontWeight: 500 }}
              className="terms-text"
            >
              6.3- Access, tamper with, or use non-public areas of the Services,
              NanoThings's computer systems, or the technical delivery systems
              of NanoThings's providers;
            </p>
            <p
              style={{ color: "#000", fontWeight: 500 }}
              className="terms-text"
            >
              6.4- Interfere with, or attempt to interfere with, the access of
              any user, host or network, including, without limitation, sending
              a virus, overloading, flooding, spamming, or mail-bombing the
              Services;
            </p>
            <p
              style={{ color: "#000", fontWeight: 500 }}
              className="terms-text"
            >
              6.5- Attempt to access or search the Services from the Services
              through the use of any tool, device or mechanism (including
              spiders, robots, crawlers, data mining tools or the like) other
              than the software and/or search agents provided by NanoThings or
              other generally available third party web browsers;
            </p>
            <p
              style={{ color: "#000", fontWeight: 500 }}
              className="terms-text"
            >
              6.6- Violate any applicable law or regulation, including by
              stalking, harassing, or tracking others for unlawful or criminal
              purposes.
            </p>
            <p
              style={{ color: "#000", fontWeight: 500 }}
              className="terms-text"
            >
              6.7- Encourage or enable any other individual to do any of the
              foregoing.
            </p>
            <br/>
            <p
              style={{ color: "#000", fontWeight: 500 }}
              className="terms-text"
            >
              <b>7-</b> If you or someone using your NanoThings Account violates
              these rules and fails to remedy this violation after a warning,
              NanoThings may take action against you, including revoking access
              to certain or all NanoThings Products and Services or terminating
              your NanoThings Account as described in Section 14. In case of
              severe violations, NanoThings may take these actions without
              issuing a prior warning. When practical, NanoThings will notify
              you of the action it will take in response to violations of these
              rules or breach of this Agreement
            </p>

            <br/> <h4 className="terms-title">8- Copyright Policy</h4>
            <p
              style={{ color: "#000", fontWeight: 500 }}
              className="terms-text"
            >
              It is our policy to remove, or disable access to, material that
              infringes any copyright on our Services after we have been
              notified by the copyright owner or the copyright owner's legal
              agent. If you believe that your work has been copied and posted on
              through our Services in a way that constitutes copyright
              infringement, please provide our copyright agent with the
              following information:
            </p>
            <p
              style={{ color: "#000", fontWeight: 500 }}
              className="terms-text"
            >
              8.1- An electronic or physical signature of the person authorized
              to act on behalf of the owner of the copyright interest;
            </p>
            <p
              style={{ color: "#000", fontWeight: 500 }}
              className="terms-text"
            >
              8.2- An identification of the copyrighted work that you claim has
              been infringed;
            </p>
            <p
              style={{ color: "#000", fontWeight: 500 }}
              className="terms-text"
            >
              8.3- A description of where the material that you claim is
              infringing is located on our Services;
            </p>
            <p
              style={{ color: "#000", fontWeight: 500 }}
              className="terms-text"
            >
              8.4- Your address, telephone number, and e-mail address;
            </p>
            <p
              style={{ color: "#000", fontWeight: 500 }}
              className="terms-text"
            >
              8.5- A written statement by you that you have a good faith belief
              that the disputed use is not authorized by the copyright owner,
              its agent, or law;
            </p>
            <p
              style={{ color: "#000", fontWeight: 500 }}
              className="terms-text"
            >
              8.6- A statement by you, made under penalty of perjury, that the
              above information in your notice is accurate and that you are the
              copyright owner or authorized to act on the copyright owner's
              behalf.
            </p>
            <br/>
            <p
              style={{ color: "#000", fontWeight: 500 }}
              className="terms-text"
            >
              <b>9-</b> Notice of claims of copyright infringement should be
              sent to our Copyright Agent either via email at{" "}
              <span>
                <a
                  style={{ color: "#2aace3", cursor: "pointer" }}
                  href="mailto:copyright@nanothingsinc.com"
                >
                  copyright@nanothingsinc.com
                </a>
              </span>
              or by mail addressed to Copyright Agent, NanoThings, Inc., 1460
              Broadway, New York, NY 11036.
            </p>

            <br/> <h4 className="terms-title">10- Third Party Links</h4>
            <p
              style={{ color: "#000", fontWeight: 500 }}
              className="terms-text"
            >
              Our Services may contain links to content or services provided by
              third parties ("Third Party Links"). These Third Party Links are
              not under our control, and we are not responsible for their
              content, services, performance, operation, availability, business
              practices or policies. We are providing these Third Party Links to
              you only as a convenience but we do not imply any endorsement or
              recommendation of their content or services, or of any association
              of us with such third parties. If you access any of these Third
              Party Links, you do this entirely at your own risk and any charges
              or obligations you incur in your dealings with these third
              parties, are your responsibility. The websites available through
              the Third Party Links are subject to their own terms and policies,
              including privacy and data gathering practices.
            </p>

            <br/> <h4 className="terms-title">11- Ownership</h4>
            <p
              style={{ color: "#000", fontWeight: 500 }}
              className="terms-text"
            >
              You acknowledge that all intellectual property rights in our
              Products, Software and Services, including the underlying software
              and technology and the information and content available on our
              Services, are owned by us and our suppliers (including other
              users), and are protected by law throughout the world. If you
              provide any suggestions, ideas, feedback, or recommendations to us
              regarding our Products, Software or Services ("Feedback"), we may
              use this Feedback for any purpose and without any obligation to
              you. By providing us with Feedback, you give us a worldwide,
              perpetual, irrevocable, fully-paid and royalty-free license to use
              and exploit in any manner any and all Feedback
            </p>
            <br/> <h4 className="terms-title">12- Updates</h4>
            <p
              style={{ color: "#000", fontWeight: 500 }}
              className="terms-text"
            >
              NanoThings may allow or require you to download updates or patches
              onto your NanoThings Software. Certain updates may be required to
              continue use of NanoThings products and services. You consent to
              NanoThings automatically installing available updates to
              NanoThings products and services. Failure to install available
              updates may render NanoThings products and/or services unusable.
              <br />
              Our Services continue to evolve as we look for ways to enhance how
              customers engage with our Services. We reserve the right to change
              and update the features of the Services we provide, including but
              not limited to those offered under our Subscriptions. We will
              provide you with notice in the event of any material change or
              update. Your continued use of NanoThings Services means you accept
              the changes. If you have any issues or concerns with a change or
              update that you have received notice about, please contact us at{" "}
              <span>
                <a
                  style={{
                    color: "#2aace3",
                    cursor: "pointer",
                    marginRight: 2,
                  }}
                  href="mailto:terms@nanothingsinc.com"
                >
                  terms@nanothingsinc.com.
                </a>
              </span>
            </p>
            <br/> <h4 className="terms-title">13- Termination</h4>
            <p
              style={{ color: "#000", fontWeight: 500 }}
              className="terms-text"
            >
              You can terminate your account at any time by deactivating your
              account or by providing notice of termination to us. We reserve
              the right to terminate or suspend your account or your access to
              any or all portions of the Services at any time, for any reason,
              including your violation or breach of any provision in these
              Terms. Upon termination, all rights and licenses granted to you in
              these Terms immediately end. If your account or access to our
              Services is terminated or suspended because you violated these
              Terms, you will not be entitled to any refund of any fees or
              payments and you will have no further right to access any of the
              foregoing or your account.
            </p>
            <br/> <h4 className="terms-title">
              14- Disclaimer of Warranty for Software and Services
            </h4>
            <p
              style={{ color: "#000", fontWeight: 500 }}
              className="terms-text"
            >
              We will use reasonable efforts to correct any discovered defects
              in the Software or Services. However, to the maximum extent
              permitted by applicable law, your access to and use of our
              Software and Services is at your own risk. We are not responsible
              for the content provided by, or the conduct of, any user and you
              bear the entire risk of using the Software and Services and any
              interactions with other users. Our Software and Services are
              provided on an "AS IS" and "AS AVAILABLE" basis and, to the
              maximum extent permitted by applicable law, we do not represent,
              warrant, or guarantee that the Software and Services will be
              provided uninterrupted, error-free, virus-free, or that defects
              will be corrected. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE
              LAW, WE DISCLAIM ALL WARRANTIES REGARDING THE APP AND SERVICES,
              WHETHER EXPRESS, IMPLIED, OR STATUTORY, INCLUDING THE WARRANTIES
              OF TITLE, MERCHANTABILITY, FITNESS FOR ANY PARTICULAR PURPOSE, OR
              NON-INFRINGEMENT. IMPORTANT: WITHOUT LIMITING THE FOREGOING, THE
              APP AND THE SERVICES ARE DESIGNED SOLELY TO HELP YOU LOCATE YOUR
              LOST PERSONAL ITEMS (SUCH AS KEYS, WALLETS, AND BACKPACKS) WITHIN
              A CLOSE PROXIMITY. THEY ARE NOT DESIGNED NOR DO THEY PROVIDE ANY
              REAL-TIME TRACKING CAPABILITIES. WE DISCLAIM ANY LIABILITY
              RESULTING FROM ANY USE OF THE APP AND/OR THE SERVICES FOR ANY
              PURPOSE OTHER THAN THEIR INTENDED DESIGN. If applicable law
              requires any warranties with respect to our Software or Services,
              all such warranties are limited in duration to the longer of
              thirty (30) days from the date of your first use or the minimum
              duration allowed by law.
            </p>
            <br/> <h4 className="terms-title">15- Limitation of Liability</h4>
            <p
              style={{ color: "#000", fontWeight: 500 }}
              className="terms-text"
            >
              EXCEPT TO THE EXTENT REQUIRED BY LAW, WE WILL NOT BE RESPONSIBLE
              FOR ANY LOST PROFITS, REVENUES, OR DATA, COST OF SUBSTITUTE GOODS
              OR SERVICES, OR ANY INDIRECT, SPECIAL, CONSEQUENTIAL, EXEMPLARY,
              OR PUNITIVE DAMAGES, HOWEVER CAUSED AND REGARDLESS OF THE THEORY
              OF LIABILITY, RELATED TO OR ARISING OUT OF THESE TERMS OR YOUR USE
              OF THE PRODUCTS, APP OR SERVICE, EVEN IF WE HAVE BEEN ADVISED OF
              THE POSSIBILITY OF SUCH DAMAGES. TO THE EXTENT PERMITTED BY LAW,
              OUR TOTAL LIABILITY FOR ANY CLAIM RELATED TO OR ARISING OUT OF
              THESE TERMS OR YOUR USE OF THE PRODUCTS, APP OR SERVICE, IS
              LIMITED TO (A) THE AMOUNT YOU HAVE PAID TO NanoThings FOR THE
              PRODUCT GIVING RISE TO THE CLAIM, (B) THE AMOUNT YOU HAVE PAID TO
              NanoThings FOR YOUR Subscription GIVING RISE TO THE CLAIM IN THE
              12 MONTHS BEFORE THE CLAIM AROSE, OR (C) FIFTY DOLLARS ($50) IF
              YOU HAVE NOT HAD ANY PAYMENT OBLIGATIONS TO NanoThings, AS
              APPLICABLE.
            </p>
            <br/> <h4 className="terms-title">16- Exclusions</h4>
            <p
              style={{ color: "#000", fontWeight: 500 }}
              className="terms-text"
            >
              Some jurisdictions do not allow the exclusion of certain
              warranties or the exclusion or limitation of liability for
              consequential or incidental damages, so the above exclusions or
              limitations may not Softwarely to you. For instance, certain
              legislation in Australia provides consumer guarantees or impose
              obligations on NanoThings which cannot be excluded, restricted or
              modified, or only to a limited extent. These Terms of Service are
              subject to such legislation, including without limitation the
              Australian Consumer Law, as set out in Schedule 2 of the
              Competition and Consumer Act 2010.
            </p>

            <br/> <h4 className="terms-title">17- General Provisions</h4>
            <p
              style={{ color: "#000", fontWeight: 500 }}
              className="terms-text"
            >
              These Terms constitute the entire agreement between us with
              respect to the subject matter and supersede any prior
              understandings and agreements. These Terms will be governed by and
              construed in accordance with the laws of the State of California,
              without regard to or Softwarelication of conflicts of law rules or
              principles. The exclusive jurisdiction for all disputes, claims or
              controversies arising out of or relating to these Terms or the
              breach, termination, enforcement, interpretation or validity
              thereof or the use of the Services or Content will be the state
              and federal courts located in the Northern District of California
              and you and NanoThings each waive any objection to jurisdiction
              and venue in such courts. If any part of these Terms is determined
              to be invalid or unenforceable by a court of competent
              jurisdiction, that provision will be enforced to the maximum
              extent permissible and the remaining provisions of these Terms
              will remain in full force and effect.
            </p>

            <br/> <h4 className="terms-title">18- Third Party Trademarks</h4>
            <p
              style={{ color: "#000", fontWeight: 500 }}
              className="terms-text"
            >
              The LoRaWAN word mark and logos are registered trademarks owned by
              the LoRa Alliance. All other trademarks and trade names are
              properties of their respective owners.
            </p>

            <br/> <h4 className="terms-title">
              19- Dispute Resolution by Binding Arbitration
            </h4>
            <p
              style={{ color: "#000", fontWeight: 500 }}
              className="terms-text"
            >
              <b>
                THIS SECTION APPLIES TO ALL CONSUMERS WHO ACCEPT THE TERMS OF
                THIS AGREEMENT. IT EXCLUDES RESIDENTS OF QUEBEC, RUSSIA,
                SWITZERLAND, BRAZIL, MEXICO, THE MEMBER STATES OF THE EEA, AND
                THE REPUBLIC OF KOREA BRINGING CLAIMS OUTSIDE OF THE UNITED
                STATES. BY ACCEPTING THE TERMS OF THIS AGREEMENT, YOU AND
                NanoThings EXPRESSLY WAIVE THE RIGHT TO A TRIAL BY JURY AND THE
                RIGHT TO PARTICIPATE IN A CLASS ACTION.
              </b>{" "}
              <br />
              This Section offers a streamlined way to resolve disputes between
              us if they arise. Most of your concerns can be resolved quickly
              and satisfactorily by contacting NanoThings customer care. If
              NanoThings cannot resolve your concern, you and NanoThings agree
              to be bound by the procedure in this Section to resolve disputes
              between us.
              <br />
              This Section is an agreement between you and NanoThings, and
              Softwarelies to our respective agents, employees, subsidiaries,
              predecessors, successors, beneficiaries and assigns. This
              agreement to arbitrate evidence of a transaction in interstate
              commerce, and thus the Federal Arbitration Act governs the
              interpretation and enforcement of this Section. This Section shall
              be interpreted broadly and shall survive termination of this
              Agreement.
            </p>
            <br/>
            <h5 className="terms-title">A. Claims Covered by Arbitration</h5>
            <p
              style={{ color: "#000", fontWeight: 500 }}
              className="terms-text"
            >
              All disputes, claims or controversies arising out of or relating
              to this Agreement, any NanoThings product or service and its
              marketing, or the relationship between you and NanoThings
              ("Disputes") shall be determined exclusively by binding
              arbitration. This includes claims that accrued before you entered
              into this Agreement. The only Disputes not covered by this Section
              are claims (i) regarding the infringement, protection or validity
              of your, NanoThings’s or NanoThings’s licensors' trade secrets or
              copyright, trademark or patent rights; (ii) if you reside in
              Australia, to enforce
            </p>
            <br/>
            <h5 className="terms-title">B. Informal Negotiations</h5>
            <p
              style={{ color: "#000", fontWeight: 500 }}
              className="terms-text"
            >
              You and NanoThings shall first attempt to resolve any Dispute
              informally for at least 30 days before initiating arbitration. The
              informal negotiations commence upon receipt of written notice from
              one person to the other ("Notice of Dispute"). The Notice of
              Dispute must: (a) include the full name and contact information of
              the complaining party; (b) describe the nature and basis of the
              claim or dispute; and (c) set forth the specific relief sought.
              NanoThings will send its Notice of Dispute to your billing or
              email address. You will send your Notice of Dispute to: NanoThings
              Inc, 1900 South Norfolk Street, Suite 310, San Mateo CA 94403,
              ATTENTION: Legal Department.{" "}
            </p>
            <br/>
            <h5 className="terms-title">C. Binding Arbitration</h5>
            <p
              style={{ color: "#000", fontWeight: 500 }}
              className="terms-text"
            >
              If you and NanoThings cannot resolve a Dispute informally, you or
              NanoThings may elect to have the Dispute finally and exclusively
              resolved by binding arbitration. Any election to arbitrate by one
              party shall be final and binding on the other. The arbitration
              shall be administered by the American Arbitration Association
              ("AAA") under its Commercial Arbitration Rules and, where
              appropriate, the AAA's Supplementary Procedures for Consumer
              Related Disputes ("AAA Consumer Rules"), both of which are
              available at the AAA website www.adr.org. Your arbitration fees
              and your share of arbitrator compensation shall be governed by the
              AAA Rules and, where appropriate, limited by the AAA Consumer
              Rules. If such costs are determined by the arbitrator to be
              excessive, or if you send NanoThings a notice to the Notice of
              Dispute address above indicating that you are unable to pay the
              fees required to initiate an arbitration, NanoThings will pay all
              arbitration fees and expenses. The arbitration may be conducted in
              person, through the submission of documents, by phone or online.
              The arbitrator shall make a decision in writing, and shall provide
              a statement of reasons if requested by either party. The
              arbitrator must follow applicable law, and any award may be
              challenged if the arbitrator fails to do so. You and NanoThings
              may litigate in court to compel arbitration, to stay proceedings
              pending arbitration, or to confirm, modify, vacate or enter
              judgment on the award entered by the arbitrator.
            </p>
            <br/>
            <h5 className="terms-title">D. Limitations</h5>
            <p
              style={{ color: "#000", fontWeight: 500 }}
              className="terms-text"
            >
              <b>
                YOU AND NanoThings AGREE THAT EACH MAY BRING CLAIMS AGAINST THE
                OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY, AND NOT AS A
                PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR
                REPRESENTATIVE PROCEEDING.
              </b>
              The arbitrator shall not consolidate another person's claims with
              your claims, and shall not preside over any type of representative
              or class proceeding. The arbitrator may award declaratory or
              injunctive relief only in favor of the individual party seeking
              relief and only to the extent necessary to provide relief
              warranted by that party's individual claim. If this specific
              subsection is found to be unenforceable, then the entirety of this
              agreement to arbitrate shall be null and void.{" "}
            </p>
            <br/>
            <h5 className="terms-title">E. Location</h5>
            <p
              style={{ color: "#000", fontWeight: 500 }}
              className="terms-text"
            >
              If you live in the United States, arbitration will take place at
              any reasonable location convenient for you. For residents outside
              the United States, arbitration shall be initiated in the County of
              San Mateo, State of California, United States of America, and you
              and NanoThings agree to submit to the personal jurisdiction of
              that court, in order to compel arbitration, to stay proceeding
              pending arbitration, or to confirm, modify, vacate or enter
              judgment on the award entered by the arbitrator.
            </p>
            <br/>
            <h5 className="terms-title">F. Recovery</h5>
            <p
              style={{ color: "#000", fontWeight: 500 }}
              className="terms-text"
            >
              If the arbitrator rules in your favor on the merits of any claim
              you bring against NanoThings and issues you an award that is
              greater in monetary value than NanoThings's last written
              settlement offer made before NanoThings makes its final written
              submissions to the arbitrator, then NanoThings will:
            </p>
            <p
              style={{ color: "#000", fontWeight: 500, marginRight: 5 }}
              className="terms-text"
            >
              1- Pay you 150% of your arbitration award, up to $5,000 USD over
              and above your arbitration award; and
            </p>
            <p
              style={{ color: "#000", fontWeight: 500, marginRight: 5 }}
              className="terms-text"
            >
              2- Reimburse the arbitration fees that you paid to the AAA.
            </p>

            <br/> <h4 className="terms-title">
              20- Changes to this Arbitration Agreement
            </h4>
            <p
              style={{ color: "#000", fontWeight: 500 }}
              className="terms-text"
            >
              NanoThings will not enforce material changes to this section in
              the future unless you expressly agree to them.
            </p>

            <br/> <h4 className="terms-title">21- Contacting Us</h4>
            <p
              style={{ color: "#000", fontWeight: 500 }}
              className="terms-text"
            >
              If you have any questions about these Terms, please contact us at{" "}
              <span>
                <a
                  style={{ color: "#2aace3", cursor: "pointer" }}
                  href="mailto:terms@nanothingsinc.com"
                >
                  terms@nanothingsinc.com.
                </a>
              </span>
            </p>
            <div className="mb-5"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Terms;
