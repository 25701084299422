import "bootstrap/dist/css/bootstrap.min.css";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import NavBar from "../src/components/NavBar";
import Footer from "./components/Footer";
import HomePage from "./pages/HomePage";
import Buy from "./pages/Buy";
import Pricing from "./pages/Pricing";
import Support from "./pages/Support";
import RealTime from "./pages/RealTime";
import ClosedLoop from "./pages/ClosedLoop";
import Terms from "./pages/Terms";
export default function App() {
  return (
    <BrowserRouter>
      {/* <NavBar /> */}
      <Routes>
        <Route>
          <Route path="/" element={<Pricing />} />
          {/* <Route path="/" element={<HomePage />} /> */}
          {/* <Route path="/buy" element={<Buy />} /> */}
          {/* <Route path="/pricing" element={<Pricing />} /> */}
          <Route path="/support" element={<Support />} />
          <Route path="/terms" element={<Terms />} />
          {/* <Route path="/real-time" element={<RealTime />} /> */}
          {/* <Route path="/closed-loop" element={<ClosedLoop />} /> */}
        </Route>
      </Routes>
      {/* <Footer /> */}
    </BrowserRouter>
  );
}
